import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {
    token: (state) => {
      let token = state.token;
      if (!token) {
        token = localStorage.getItem("accessToken");
      }
      return token;
    },
  },
  mutations: {
    //设置token
    // setAccessToken(state, data) {
    //   state.token = data;
    //   localStorage.setItem("accessToken", data);
    // },
  },
  actions: {
    // setIsSupportQing(context, data) {
    //   context.commit("setIsSupportQing", data);
    // },
  },
  modules: {},
});
