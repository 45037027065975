<template>
  <div class="app">
    <keep-alive>
      <!--使用keep-alive会将页面缓存-->
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
import Vue from "vue";
import utils from "@/utils/utils.js";
import axios from "axios";
import rem from "@/utils/rem.js";
import VConsole from "vconsole";
export default {
  computed: {},
  created() {},
  watch: {
    $route(to, from) {},
  },
  mounted() {
    // 计算rem
    rem();
    // new VConsole();
  },
  methods: {},
};
</script>

<style lang="scss">
@import "@/styles/reset.scss";
@import "@/styles/common.scss";
</style>

<style lang="scss">
.app {
  position: relative;
}
</style>
