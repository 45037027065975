// rem布局函数
let bodyMaxWidth = 550;
export default function() {
  !(function(n) {
    var e = n.document,
      t = e.documentElement,
      b = e.body,
      i = 750,
      d = i / 100,
      o = "orientationchange" in n ? "orientationchange" : "resize",
      a = function() {
        var n = t.clientWidth || 375;
        n > bodyMaxWidth && (n = bodyMaxWidth);
        t.style.fontSize = n / d + "px";
        b.style.maxWidth = bodyMaxWidth + "px";
        b.style.margin = "0 auto";
      };
    e.addEventListener &&
      (n.addEventListener(o, a, !1),
      e.addEventListener("DOMContentLoaded", a, !1));
  })(window);
}
