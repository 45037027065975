import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vglobal from "./global.js";
import VConsole from "vconsole";

import {
  List,
  PullRefresh,
  NavBar,
  Dialog,
  Checkbox,
  CheckboxGroup,
  DatetimePicker,
  Calendar,
  Cascader,
  Icon,
  Toast,
  Cell,
  CellGroup,
  Radio,
  RadioGroup,
  Popup,
  Picker,
  Tabbar,
  TabbarItem,
  Tab,
  Tabs,
  DropdownMenu,
  DropdownItem,
  Search,
  ActionSheet,
  Button,
  Col,
  Row,
  Swipe,
  SwipeItem,
  image,
  Area,
  Skeleton,
  Collapse,
  CollapseItem,
  Popover,
  Stepper,
  Badge,
  Grid,
  GridItem,
  ImagePreview,
  SwipeCell,
  Switch,
  Rate,
  Circle,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton
} from "vant";

Vue.use(ActionSheet);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Search);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Toast);
Vue.use(Calendar);
Vue.use(Icon);
Vue.use(DatetimePicker);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Dialog);
Vue.use(NavBar);
Vue.use(PullRefresh);
Vue.use(List);
Vue.use(Button);
Vue.use(Col);
Vue.use(Row);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(image);
Vue.use(Area);
Vue.use(Cascader);
Vue.use(Skeleton);
Vue.use(CollapseItem);
Vue.use(Collapse);
Vue.use(Popover);
Vue.use(Stepper);
Vue.use(Badge);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(ImagePreview);
Vue.use(SwipeCell);
Vue.use(Switch);
Vue.use(Rate);
Vue.use(Circle);
Vue.use(GoodsAction);
Vue.use(GoodsActionIcon);
Vue.use(GoodsActionButton);
Vue.config.productionTip = false;

// new VConsole();

let vueapp = new Vue({
  router,
  store,
  render: (h) => h(App),
});
Vue.prototype.$EventBus = new Vue();
Vue.prototype.$global = vglobal(vueapp);
vueapp.$mount("#app");

export default vueapp;
